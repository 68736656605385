<template>
  <div class="common-wrap">
    <div class="common-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommonWrap"
};
</script>

<style lang="scss" scope>
.common-wrap {
  // height: 50px;
  line-height: 50px;
  padding: 0 20px;
  .common-content {
    height: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #e8ecf2;
  }
}
</style>
