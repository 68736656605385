/*
 * @Author: zoujp
 * @Date: 2021-02-26 09:24:27
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-03-06 11:08:04
 * @Description: file content
 */
import AddressBookService from "./addressBookServeice";

export const addressBookService = new AddressBookService();
