<!--
 * @Author: zoujp
 * @Date: 2021-03-01 16:37:28
 * @LastEditTime: 2021-03-26 11:38:55
 * @LastEditors: Please set LastEditors
 * @Description: 通讯录-顶部
 * @FilePath: \mailbox\src\views\AddressBook\index.vue
-->
<template>
  <div class="ab-top">
    <div class="ab-top-inner">
      <div class="tit">
        <enIcon title="返回" name="fanhui" @click.native="back"></enIcon>
        <div class="txts">{{ sourceType===3?"全局搜索":"通讯录" }}</div>
      </div>
      <div class="search">
        <enIcon title="搜索" name="sousuo" @click.native="search" v-show="searchFlag === 1"></enIcon>
        <en-search ref="searchIpt" v-show="searchFlag === 2" v-model="searchValue" @search="searchSure" :showBorder="true"> </en-search>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AbTop",
  computed: {},
  inject: ["$home"],
  props: {
    sourceType: {
      type: Number,
      default: () => 1
    }
  },
  data() {
    return {
      searchValue: "",
      searchFlag: 1 // 1未打开，2打开
    };
  },
  mounted() {},
  watch: {
    sourceType() {
      const t = this;
      this.$nextTick(() => {
        t.$refs.searchIpt.$el.getElementsByTagName("input")[0].focus();
      });
    }
  },
  methods: {
    back() {
      this.searchValue = "";
      this.$home.backPage();
    },
    search() {
      this.searchFlag = 2;
      this.$home.searchClick();
    },
    searchSure() {
      this.$home.searchSure(this.searchValue);
    }
  }
};
</script>
<style lang="scss" scoped>
.ab-top {
  width: 100%;
  height: 60px;
  background-color: #fff;
  .ab-top-inner {
    padding: 0 20px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tit {
      display: flex;
      align-items: center;
      .txts {
        font-weight: bold;
        font-size: 14px;
        padding-left: 5px;
        color: #333;
      }
    }
    /deep/ .en-icon {
      color: #a8b3c5;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
    .search {
      cursor: pointer;
    }
  }
}
</style>
