<!--
 * @Author: zoujp
 * @Date: 2021-03-01 16:37:28
 * @LastEditTime: 2021-04-19 09:51:39
 * @LastEditors: Please set LastEditors
 * @Description: 通讯录
 * @FilePath: \mailbox\src\views\AddressBook\index.vue
-->
<template>
  <div class="container">
    <abTop ref="popTop" :sourceType="sourceType"></abTop>
    <div class="ab-center">
      <abLeft :menuList="menuList" :classify="classify" v-loading="menuLoading" v-show="sourceType !== 3"></abLeft>
      <div class="ab-right">
        <div class="btn-wrap">
          <div class="inner-wrap">
            <div class="tit">
              <span class="title">{{ sourceType===3?"搜索结果":(sourceData.name || "") }}</span>
              <span class="number">{{ "(" + (pageModel.total || 0) + ")" }}</span>
            </div>
            <div class="but">
              <div class="button write" @click="writeTop">
                <enIcon title="写信" name="wodeyouxiang-dingbu" size="mini"></enIcon>
                <span>写信</span>
              </div>
              <div class="button delete" @click="delTop" v-show="sourceType === 2">
                <enIcon title="删除" name="shanchu" size="mini"></enIcon>
                <span>删除</span>
              </div>
              <div class="button-add" @click="addTop" v-show="sourceType === 2">
                <div class="inner-btn">
                  <enIcon title="删除" name="bus-btn1_1" size="mini"></enIcon>
                  <div class="btn-txts">添加</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table-wrap">
          <en-table ref="addressTable" :data="dataRecords" :loading="tableLoading" :height-config="{ bottomHeight }" @select-change="handleSelectChange" auto-resize>
            <en-table-column type="index-selection"></en-table-column>
            <en-table-column title="姓名" field="name">
              <template v-slot="{ row }">
                <div class="custom-row">
                  <en-user-logo :user-name="getFirstName(row)" size="30"></en-user-logo>
                  <div class="row-txts">
                    <span>{{ row.name }}</span>
                  </div>
                </div>
              </template>
            </en-table-column>
            <en-table-column title="邮箱" field="address"></en-table-column>
            <template #hoverRow="{row, rowIndex}">
              <en-expand-buttons
                :data="getButtonData(row)"
                :minNumber="3"
                class="row-btn-css"
                :showToolTips="true"
                style="margin-right:20px"
                @button-click="
                  handleButtonClick({
                    row,
                    rowIndex,
                    btnData: arguments[1],
                    btnIndex: arguments[0]
                  })
                "
              >
              </en-expand-buttons>
            </template>
            <en-table-column title="来源" field="name" v-if="sourceType === 3">
              <template v-slot="{ row }">
                <div class="row-txts">
                  <span>{{ row.classRefId?"来源个人通讯录":"来源公司通讯录" }}</span>
                </div>
              </template>
            </en-table-column>
          </en-table>
          <en-pagination :page-model="pageModel" v-if="dataRecords.length" @change="handlePageChanged">
            <template v-slot:left>
              <div class="page-count" v-show="checkResult.length">
                <span class="page-selected"
                  >已选<span>{{ checkResult.length }}</span
                  >条数据</span
                >
                <span class="page-clear" @click="clearSelected">清空</span>
              </div>
            </template>
          </en-pagination>
        </div>
      </div>
    </div>
    <addClassify ref="popAddClassify" :editData="editClassifyObj"></addClassify>
    <addContact ref="popAddContact" :classifyData="classify" :editContactObj="editContactObj" :initClassify="sourceData.id"></addContact>
    <moveContact ref="popMoveContact" :classifyData="classify" :editContactObj="editContactObj"></moveContact>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash";
import { addressBookService } from "@/api/mailBox/addressBook";
import abTop from "./top";
import abLeft from "./left";
import addClassify from "./addClassify";
import addContact from "./addContact";
import moveContact from "./moveContact";
/* eslint-disable */

export default {
  name: "AddressBook",
  components: {
    abTop,
    abLeft,
    addClassify,
    addContact,
    moveContact
  },
  data() {
    return {
      menuLoading: false,
      tableLoading: false,
      sourceType: 1, // 0不选中 1企业 2 个人 3搜索
      sourceData: {},
      menuList: [],
      editClassifyObj: {},
      editContactObj: {},
      classify: [],
      seParams: {
        pageNo: 1,
        pageSize: 10,
        id: "",
        userId: "",
        searchValue: ""
      },
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        totalPages: 0
      },
      dataRecords: [],
      checkResult: []
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    bottomHeight() {
      if (this.dataRecords.length) {
        return 68;
      }
      return 18;
    }
  },
  mounted() {
    this.initTree();
    this.initClassify();
  },
  provide() {
    return {
      $home: this
    };
  },
  methods: {
    getFirstName (row){
      return (row.name || '').split("")[0] || '';
    },
    // 根据类型获取行悬停按钮
    getButtonData(row) {
      let b1 = [
        {
          icon: "shengchengyoujian",
          title: "写信",
          name: "写信",
          iconColor: "#3e90fe"
        }
      ];
      let b2 = [
        {
          icon: "shengchengyoujian",
          title: "写信",
          name: "写信",
          iconColor: "#3e90fe"
        },
        {
          icon: "bianji-liebiao",
          title: "编辑",
          name: "编辑",
          iconColor: "#25c393"
        },
        {
          icon: "shanchu-liebiao",
          title: "删除",
          name: "删除",
          iconColor: "#f56b6b"
        }
      ];
      if (row.classRefId) {
        return b2;
      } else {
        return b1;
      }
    },
    writeTop() {
      const re = this.getSelected();
      this.write(re);
    },
    delTop() {
      const re = this.getSelected();
      this.delContact(re.all);
    },
    delContact(ids) {
      if (!ids) {
        this.$message({
          message: "请选择联系人",
          type: "warning"
        });
        return true;
      }
      const _this = this;
      const h = this.$createElement;
      this.$msgbox({
        title: "",
        closeOnClickModal: false,
        message: h("div", { style: { display: "flex", alignItems: "center", fontSize: "12px" } }, [
          h("en-icon", { attrs: { name: "tishi", color: "#F76B6A" } }),
          h("div", { style: { marginLeft: "10px" } }, [ h("p", {}, "确定删除该联系人？")])
        ]),
        confirmButtonClass: "mail-btn-error",
        async callback(action) {
          if (action === "confirm") {
            _this.delSure(ids);
          }
        }
      });
    },
    async delSure(ids) {
      try {
        const params = {
          userId: this.$store.state.userInfo.userId,
          ids
        };
        await addressBookService.deleteContact(params);
        this.$message({
          message: "删除成功",
          type: "success"
        });
        Object.assign(this.pageModel, this.$options.data().pageModel);
        this.getDataList();
      } catch (error) {
        console.log("error");
      }
    },
    handleButtonClick({ row, rowIndex, btnData, btnIndex }) {
      // 写信
      if (btnIndex === 0) {
        if (row.classRefId) {
          this.write({ p: row.id });
        } else {
          this.write({ c: row.id });
        }
      }
      // 编辑
      if (btnIndex === 1) {
        this.editContactObj = row;
        this.$refs.popAddContact.visiable = true;
      }
      // 删除
      if (btnIndex === 2) {
        this.delContact(row.id);
      }
    },
    async initTree() {
      try {
        this.menuLoading = true;
        const rsp = await addressBookService.getMenuList({ objectDataType: "001" });
        this.menuLoading = false;
        const arr = cloneDeep(rsp);
        this.initList(arr, true);
        this.menuList = arr;
        //默认查询第一个节点的数据
        this.handleClassClick(arr[0]);
      } catch (error) {
        this.menuLoading = false;
      }
    },
    async initClassify() {
      try {
        this.menuLoading = true;
        const rsp = await addressBookService.getMenuClassify({ userId: this.userInfo.userId });
        this.menuLoading = false;
        const arr = cloneDeep(rsp);
        arr.forEach((item) => {
          item.__c = true; //表示为分组
        });
        this.classify = arr;
      } catch (error) {
        this.menuLoading = false;
      }
    },
    // 初始化 文档目录数据
    initList(list, first) {
      list.forEach((itm, idx) => {
        itm.cur = false;
        itm.open = false;
        if (first && idx === 0) {
          itm.isFirst = true;
        }
        if (itm.nodes && itm.nodes.length) {
          this.initList(itm.nodes);
        }
      });
    },
    // 返回
    backPage() {
      if (this.sourceType === 3) {
        if (this.sourceData?.id) {
          if (this.sourceData?.__c) {
            this.sourceType = 2;
          } else {
            this.sourceType = 1;
          }
        }
        this.$refs.popTop.searchFlag = 1;
        Object.assign(this.pageModel, this.$options.data().pageModel);
        this.getDataList();
      } else {
        this.$router.back(-1);
      }
    },
    // 搜素
    searchClick() {
      this.sourceType = 3;
      this.seParams.searchValue = "";
      Object.assign(this.pageModel, this.$options.data().pageModel);
      this.getDataList();
    },
    // 搜素确定
    searchSure(val) {
      this.seParams.searchValue = val;
      Object.assign(this.pageModel, this.$options.data().pageModel);
      this.getDataList();
    },
    // 获取选中的数据
    getSelected() {
      const p = [],
        all = [],
        c = [];
      this.checkResult.forEach((item) => {
        all.push(item.id);
        if (item.classRefId) {
          p.push(item.id);
        } else {
          c.push(item.id);
        }
      });
      return {
        c: c.join(","),
        p: p.join(","),
        all: all.join(",")
      };
    },
    // 触发添加分组
    addClassifyClick() {
      this.$refs.popAddClassify.visiable = true;
      this.editClassifyObj = {};
    },
    // 保存分组后回调方法
    saveClassifyCallback() {
      this.initClassify();
      setTimeout(() => {
        if (this.sourceType == 2 && this.sourceData) {
          this.clickClassify(this.sourceData);
        }
      }, 2000);
    },
    // 保存联系人后回调方法
    saveContactCallback() {
      this.getDataList();
    },
    // 清空列表选中
    clearSelected() {
      this.checkResult = [];
      this.$refs.addressTable.setAllSelection(false);
    },
    // 点击分组
    clickClassify(node) {
      const id = node.id;
      this.sourceType = 2;
      this.sourceData = node;
      this.classify.forEach((itm) => {
        this.$set(itm, "cur", itm.id === id);
      });
      this.unSelectTreeNode();
      this.seParams.id = id;
      this.seParams.searchValue = "";
      Object.assign(this.pageModel, this.$options.data().pageModel);
      this.checkResult = [];
      this.getDataList();
    },
    // 编辑分类
    editClassify(obj) {
      this.editClassifyObj = obj;
      this.$refs.popAddClassify.visiable = true;
    },
    // 删除分类
    delClassifyCallback(id) {
      this.initClassify();
      setTimeout(() => {
        if (this.sourceType == 2 && this.sourceData) {
          if (id === this.sourceData.id) {
            if (this.classify.length) {
              this.sourceData = this.classify[0];
            } else {
              this.sourceData = this.menuList[0];
            }
          }
          this.clickClassify(this.sourceData);
        }
      }, 1000);
    },
    // check row
    handleSelectChange({ selection }) {
      this.checkResult = selection;
    },
    // 分类点击
    handleClassClick(node) {
      const id = node.id;
      this.sourceType = 1;
      this.sourceData = node;
      this.menuList.forEach((itm) => {
        this.$set(itm, "cur", itm.id === id);
        this.checKNodeCur(id, itm.nodes || []);
      });
      this.classify.forEach((itm) => {
        this.$set(itm, "cur", false);
      });
      this.seParams.id = id;
      this.seParams.searchValue = "";
      Object.assign(this.pageModel, this.$options.data().pageModel);
      this.getDataList();
    },
    checKNodeCur(id, list) {
      list.forEach((itm) => {
        this.$set(itm, "cur", itm.id === id);
        if (itm.nodes && itm.nodes.length) {
          this.checKNodeCur(id, itm.nodes || []);
        }
      });
    },
    // 子目录点击
    handleNodeClick(node) {
      const id = node.id;
      this.sourceType = 1;
      this.sourceData = node;
      this.menuList.forEach((itm) => {
        this.$set(itm, "cur", false);
        this.checKNodeCur(id, itm.nodes || []);
      });
      this.classify.forEach((itm) => {
        this.$set(itm, "cur", false);
      });
      this.seParams.searchValue = "";
      this.seParams.id = id;
      Object.assign(this.pageModel, this.$options.data().pageModel);
      this.getDataList();
    },
    // 取消tree 选中状态
    unSelectTreeNode() {
      this.menuList.forEach((itm) => {
        this.$set(itm, "cur", false);
        this.unSelectTreeNodeChildren(itm.nodes || []);
      });
    },
    unSelectTreeNodeChildren(list) {
      list.forEach((itm) => {
        this.$set(itm, "cur", false);
        if (itm.nodes && itm.nodes.length) {
          this.unSelectTreeNodeChildren(itm.nodes || []);
        }
      });
    },
    // 获取邮箱列表
    async getDataList() {
      try {
        this.tableLoading = true;
        this.seParams.userId = this.userInfo.userId;
        Object.assign(this.seParams, this.pageModel);
        let rsp = {};
        if (this.sourceType === 1) {
          rsp = await addressBookService.searchCompanyContact(this.seParams);
        } else if (this.sourceType === 2) {
          rsp = await addressBookService.getContactList(this.seParams);
        } else if (this.sourceType === 3) {
          rsp = await addressBookService.searchContact(this.seParams);
        }
        this.tableLoading = false;
        this.dataRecords = rsp.records || [];
        this.pageModel.pageNo = rsp.pageNo;
        this.pageModel.pageSize = rsp.pageSize;
        this.pageModel.total = rsp.total;
        this.pageModel.totalPages = rsp.totalPages;
      } catch (error) {
        this.tableLoading = false;
      }
    },
    // 分页器事件
    handlePageChanged({ pageSize, pageNo }) {
      this.$set(this.pageModel, "pageNo", pageNo);
      this.$set(this.pageModel, "pageSize", pageSize);
      this.getDataList();
    },
    // 写信
    write(re) {
      this.$router.push({ path: "/mailbox/mailSend", query: { c: re.c, p: re.p } });
    },
    // 添加列表
    addTop() {
      this.$refs.popAddContact.visiable = true;
      this.editContactObj = {};
    }
  }
};
</script>
<style lang="scss" scoped>
.row-btn-css{
  margin-right: 20px;
}
.container {
  width: 100%;
  height: 100%;
  background-color: #ecf1f7;
  .ab-center {
    display: flex;
    width: 100%;
    height: calc(100% - 60px);
    padding: 8px;
    .ab-right {
      flex: 1;
      height: 100%;
      overflow-y: auto;
      background-color: #fff;
      border-radius: 4px;
      .btn-wrap {
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid #e8ebf2;
        .inner-wrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 20px;
          .title {
            color: #333;
            font-size: 14px;
            font-weight: bold;
          }
          .number {
            color: #91a1b7;
            font-size: 14px;
          }
          .but {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .button {
              margin-left: 15px;
              display: flex;
              align-items: center;
              cursor: pointer;
              span {
                font-size: 12px;
                color: #636c78;
                padding-left: 5px;
              }
            }
            .write {
              .en-icon {
                color: #3e90fe;
              }
            }
            .delete {
              .en-icon {
                color: #f96b69;
              }
            }
            .button-add {
              width: 70px;
              height: 30px;
              background-color: #3e90fe;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 4px;
              margin-left: 15px;
              cursor: pointer;
              .inner-btn {
                padding: 10px 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-size: 12px;
                .btn-txts{
                  margin-left: 3px;
                }
              }
            }
          }
        }
      }
    }
    /deep/ .table-wrap {
      padding-top: 10px;
      .custom-row {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 8px 0;
        cursor: pointer;
        .row-txts {
          padding-left: 10px;
        }
      }
      .en-icon {
        width: 20px;
        height: 20px;
      }
      .page-count {
        display: flex;
        margin-left: 20px;
        align-items: center;
        font-size: 12px;
        .page-clear {
          padding-left: 15px;
          color: #3e90fe;
          cursor: pointer;
        }
        .page-selected {
          span {
            color: #3e90fe;
          }
        }
      }
    }
    /deep/ .en-user-logo{
      font-weight: normal !important;
    }
  }
}
</style>
