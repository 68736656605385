var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ab-left" }, [
    _vm._m(0),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "mod-company" }, [
        _c("div", { staticClass: "txts" }, [_vm._v("公司通讯录")]),
        _c(
          "div",
          { staticClass: "book-tree" },
          _vm._l(_vm.mList, function (itm, idx) {
            return _c("collapse-tree", { key: idx, attrs: { node: itm } })
          }),
          1
        ),
      ]),
      _c("div", { staticClass: "mod-group" }, [
        _c("div", { staticClass: "txts" }, [_vm._v("个人通讯录")]),
        _c(
          "div",
          { staticClass: "book-group" },
          _vm._l(_vm.cList, function (itm, idx) {
            return _c(
              "div",
              {
                key: idx,
                class: ["temp", itm.cur ? "cur" : ""],
                on: {
                  click: function ($event) {
                    return _vm.handleClick(itm)
                  },
                },
              },
              [
                _c("en-icon", {
                  attrs: { name: itm.icon, color: itm.color, size: "mini" },
                }),
                _c("div", { staticClass: "name" }, [_vm._v(_vm._s(itm.name))]),
                _c(
                  "div",
                  { staticClass: "handle-wrap" },
                  [
                    _c("enIcon", {
                      attrs: { title: "", name: "bianji1", size: "mini" },
                    }),
                    _c("div", { staticClass: "handle-lis" }, [
                      _c("ul", [
                        _c(
                          "li",
                          {
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleEdit(itm)
                              },
                            },
                          },
                          [_c("span", [_vm._v("编辑")])]
                        ),
                        _c(
                          "li",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.cList.length !== 1,
                                expression: "cList.length!==1",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleDel(itm)
                              },
                            },
                          },
                          [_c("span", [_vm._v("删除")])]
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tit" }, [
      _c("div", { staticClass: "txts" }, [_vm._v("通讯信息")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }