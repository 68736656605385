var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("common-wrap", [
    _c(
      "div",
      { staticClass: "subject-wrap" },
      [
        _c("span", { staticClass: "name-wrap" }, [
          _c("span", { staticClass: "name" }, [_vm._v("主题")]),
        ]),
        _c("el-input", {
          ref: "elInput",
          attrs: { maxlength: "50" },
          model: {
            value: _vm.inputValue,
            callback: function ($$v) {
              _vm.inputValue = $$v
            },
            expression: "inputValue",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }