<template>
  <div class="mail-footer">
    <div>
      <el-popover
        placement="top-start"
        popper-class="mail-popover"
        trigger="click"
       >
        <div class="user-list">
          <div class="user-item" v-for="(item, index) in userList" :key="index" @click="activeIndex = index" :class="{'active-user-item': activeIndex === index}">
            <el-avatar :size="26" style="margin-right: 10px;" :style="{background: bgColor(index)}"> {{item.name[0]}} </el-avatar>
            <div class="user-info">
              <span>{{item.name}}</span>
              <span> ({{item.mailFullAccount}})</span>
            </div>
          </div>
        </div>
        <span style="cursor: pointer;" slot="reference">
          <span class="user">
            {{currentUser.name || detailSendUser.name || "--"}}
            ({{currentUser.mailFullAccount || detailSendUser.mailFullAccount || "--"}})
          </span>
          <i class="el-icon-arrow-down"></i>
        </span>
      </el-popover>
      <span class="division"></span>
      <el-popover
        placement="top-start"
        popper-class="operation-popover"
        trigger="click"
      >
        <div class="operation-list">
          <div class="operation-item">
            <el-checkbox v-model="urgentChecked">紧急</el-checkbox>
          </div>
          <div class="operation-item">
            <el-checkbox v-model="receiptChecked">回执</el-checkbox>
          </div>
        </div>
        <span style="cursor: pointer;" slot="reference">
          <span class="operation-text">更多信息</span>
          <i class="iconfont icon-more">&#xe6aa;</i>
        </span>
      </el-popover>
    </div>
    <div>
      <el-button size="small" class="mail-btn" :loading="btnLoading" @click="sendMail(1)">存草稿</el-button>
      <el-button size="small" class="mail-btn" style="background-color: #26c393 !important; border-color: #26c393 !important;"
        :loading="btnLoading" type="primary" @click="sendApproveMail(2)" v-if="hasApproveBtn">
        审批发送</el-button>
      <el-button size="small" class="mail-btn" :loading="btnLoading" type="primary" @click="sendMail(2)">发送</el-button>
    </div>
  </div>
</template>

<script>
import { mailSendService } from "@/api/mailBox/mailSend";

export default {
  name: "MailFooter",
  props: {
    hasApproveBtn: {
      type: Boolean,
      default: false
    },
    urgentFlag: {
      type: Number,
      default: 1
    },
    receiptFlag: {
      type: Number,
      default: 1
    },
    // 详情传过来的发送者
    detailSendUser: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      activeIndex: 0,
      btnLoading: false,
      userList: [
        // {
        //   avatarAddr: "#996d56", mailAccount: "apple@enfry.com", name: "苹果账号", mailFullAccount: "apple@enfry.com", id: 1
        // },
      ],
      urgentChecked: this.urgentFlag === 0,
      receiptChecked: this.receiptFlag === 0
    };
  },
  methods: {
    sendApproveMail() {
      this.$emit("sendApproveMail");
    },
    bgColor(index) {
      const color = ["#3e90fe", "#26c393", "#ffad2c", "#f76b6b"];
      return color[index % 4];
    },
    sendMail(type) {
      this.$emit("sendMail", type);
    },
    getActiveIndex() {
      this.activeIndex = this.userList.findIndex((item) => item.id === this.detailSendUser.id);
      if (this.activeIndex === -1) {
        this.activeIndex = this.userList.findIndex((item) => item.isDefault === 0);
      }
      this.activeIndex = this.activeIndex === -1 ? 0 : this.activeIndex;
    },
    async getMailAccount() {
      const res = await mailSendService.getMailAccount();
      this.userList = (res || []).filter((item) => item.status === 0);
    }
  },
  watch: {
    urgentFlag(val) {
      this.urgentChecked = val === 0;
    },
    receiptFlag(val) {
      this.receiptChecked = val === 0;
    },
    detailSendUser() {
      this.getActiveIndex();
    },
    userList() {
      this.getActiveIndex();
    }
  },
  computed: {
    currentUser() {
      return this.userList[this.activeIndex] || {};
    }
  },
  mounted() {
    this.getMailAccount();
  }
};
</script>

<style lang="scss">
.mail-popover, .operation-popover {
  padding: 0;
}
.operation-list {
  .el-checkbox__label {
    font-size: 12px;
  }
}
.mail-footer {
  .mail-btn {
    height: 32px;
    line-height: 32px;
    font-size: 12px;
    box-sizing: border-box;
    padding: 0;
    width: 72px;
  }
}
</style>

<style lang="scss" scoped>
.mail-footer {
  display: flex;
  justify-content: space-between;
  background: #fff;
  height: 64px;
  line-height: 64px;
  padding: 0 20px;
  .user {
    font-size: 12px;
  }
  .el-icon-arrow-down {
    position: relative;
    top: 2px;
    left: 6px;
    font-size: 14px;
    color: #61a4e4;
  }
  .operation-text {
    font-size: 12px;
    color: #636c78;
  }
  .icon-more {
    position: relative;
    top: 2px;
    left: 4px;
    font-size: 18px;
    color: #58cb7e;
  }

  .division {
    display: inline-block;
    width: 1px;
    height: 12px;
    background: #aeb9c9;
    margin: 0 20px;
  }
}
.operation-popover {
  .operation-list {
    width: 142px;
    .operation-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      height: 40px;
      font-size: 12px;
      padding: 0 0 0 20px;
      &:hover {
        background: #f5f8fc;
      }
    }
  }
}
.mail-popover {
  .user-list {
    position: relative;
    max-height: 300px;
    overflow: auto;
    width: 420px;
    .user-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      height: 44px;
      font-size: 12px;
      padding: 0 0 0 16px;
      &:hover {
        background: #f5f8fc;
      }
      .avatar {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        border-radius: 15px;
        color: #fff;
        text-align: center;
        line-height: 30px;
      }
      .user-info {
        p {
          margin: 0;
        }
      }
    }
    .active-user-item {
      background: #f5f8fc;
    }
  }
}
</style>
