<!--
 * @Author: zoujp
 * @Date: 2021-03-03 11:48:49
 * @LastEditTime: 2021-04-25 17:27:37
 * @LastEditors: Please set LastEditors
 * @Description: 通讯录-新增联系人
 * @FilePath: \mailbox\src\views\AddressBook\addContact.vue
-->
<template>
  <el-dialog :title="edData && edData.id ? '编辑联系人' : '新增联系人'" width="700px" v-if="visiable" :visible.sync="visiable" :close-on-click-modal="false" @close="closeDialog">
    <div class="content-wrap" v-loading="loading">
      <el-form ref="form" :model="form" label-width="60px">
        <el-form-item label="姓名">
          <el-input v-model="form.name" size="medium" maxlength="100"></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input v-model="form.address" size="medium" maxlength="100"></el-input>
        </el-form-item>
        <el-form-item label="所属分组">
          <en-select
            v-model="form.classRefId"
            placeholder="请选择"
            :data="cfData"
            :props="defaultProps"
            align="left"
            data-mode="value"
            allow-create
            custom-option-add
            @custom-option-add-method="onCustomOptionAddMethod"
          >
            <template #content="{ node }">
              <div class="sel-wrap">
                <en-icon :name="node.data.icon" size="16" :color="node.data.color"></en-icon>
                <div class="sel-txt">{{ node.data.name }}</div>
              </div>
            </template>
          </en-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <div class="btn-cls" @click="save">
        <div class="btn-txt">确定</div>
      </div>
    </span>
  </el-dialog>
</template>
<script>
import { addressBookService } from "@/api/mailBox/addressBook";

/* eslint-disable */
export default {
  name: "addContact",
  props: {
    message: {
      type: String,
      default: () => ""
    },
    classifyData: {
      type: Array,
      default: () => []
    },
    editContactObj: {
      type: Object,
      default: () => {}
    },
    initClassify: {
      type: String,
      default: () => ""
    }

  },
  inject: ["$home"],
  data() {
    return {
      visiable: false,
      loading: false,
      cfData: this.classifyData || {},
      edData: this.editContactObj || {},
      form: {
        id: "",
        name: "",
        address: "",
        classRefId: this.initClassify
      },
      defaultProps: {
        label: "name",
        value: "id"
      }
    };
  },
  watch: {
    classifyData(val) {
      this.cfData = val;
    },
    visiable(val){
      if(val){
        var t = this;
        this.$nextTick(() => {
          t.form.classRefId = t.initClassify;
        })
      }
    },
    editContactObj(val) {
      this.edData = val;
      this.initData();
    }
  },
  computed: {},
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      if (this.edData?.id) {
        Object.assign(this.form, this.edData);
      } else {
        Object.assign(this.form, this.$options.data().form);
      }
    },
    closeDialog() {
      this.visiable = false;
    },
    async save() {
      if (!this.form.name) {
        this.$message({
          message: "姓名不能为空",
          type: "warning"
        });
        return true;
      }
      if (!this.form.address) {
        this.$message({
          message: "邮箱不能为空",
          type: "warning"
        });
        return true;
      }
      var reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/; //带点
      if (!reg.test(this.form.address)) {
        this.$message({
          message: "邮箱格式错误",
          type: "warning"
        });
        return true;
      }
      if (!this.form.classRefId) {
        this.$message({
          message: "所属分组不能为空",
          type: "warning"
        });
        return true;
      }
      try {
        this.loading = true;
        let params = {
          userId: this.$store.state.userInfo.userId
        };
        Object.assign(params, this.form);
        if (this.edData?.id) {
          params.id = this.edData.id;
          await addressBookService.modifyContact(params);
        } else {
          await addressBookService.addContact(params);
        }
        this.loading = false;
        this.visiable = false;
        this.$message({
          message: "保存成功",
          type: "success"
        });
        this.$home.saveContactCallback();
      } catch (error) {
        this.loading = false;
      }
    },
    onCustomOptionAddMethod() {
      this.$home.addClassifyClick();
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .el-dialog {
  border-radius: 4px;
}
/deep/ .el-form-item {
  margin-bottom: 15px;
  .el-form-item__label {
    font-size: 12px;
    color: #333;
  }
  .el-input__inner {
    font-size: 12px;
    color: #333;
  }
  .en-select {
    width: 100%;
  }
}
.sel-wrap {
  display: flex;
  align-items: center;
  .sel-txt {
    margin-left: 5px;
  }
  /deep/ .en-icon {
    width: 16px;
    height: 16px;
  }
}
.content-wrap {
  .icon-group {
    display: flex;
    padding: 10px;
    .icon-mod {
      background: #fff;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      .en-icon {
        width: 20px;
        height: 20px;
        &.cur {
          background: #3e90fe;
        }
      }
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .btn-cls {
    width: 70px;
    height: 30px;
    background: #3e90fe;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
  }
}
</style>
