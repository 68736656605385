var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visiable
    ? _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.edData && _vm.edData.id ? "编辑分组" : "新增分组",
            width: "700px",
            visible: _vm.visiable,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visiable = $event
            },
            close: _vm.closeDialog,
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "content-wrap",
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.form, "label-width": "60px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "分组名称" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "20" },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-form-item", { attrs: { label: "分组图标" } }, [
                    _c(
                      "div",
                      { staticClass: "icon-group" },
                      _vm._l(_vm.iconData, function (item, idx) {
                        return _c(
                          "div",
                          {
                            key: idx,
                            staticClass: "icon-mod",
                            class: [item.cur ? "cur" : ""],
                            on: {
                              click: function ($event) {
                                return _vm.clickIcon(item.id)
                              },
                            },
                          },
                          [_c("en-icon", { attrs: { name: item.id } })],
                          1
                        )
                      }),
                      0
                    ),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "图标颜色" } },
                    [
                      _c("el-color-picker", {
                        model: {
                          value: _vm.form.color,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "color", $$v)
                          },
                          expression: "form.color",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("div", { staticClass: "btn-cls", on: { click: _vm.save } }, [
                _c("div", { staticClass: "btn-txt" }, [_vm._v("确定")]),
              ]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }