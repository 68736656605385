var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mail-send-wrapper" },
    [
      _c("top-header", {
        attrs: { "nav-name": _vm.navName },
        on: { backHandle: _vm.backHandle },
      }),
      _c("div", { staticClass: "main-send-container" }, [
        _c(
          "div",
          { staticClass: "main-send-content" },
          [
            _c("select-contact", {
              ref: "receiver",
              staticClass: "receiver",
              attrs: { "can-change-mode": "", "select-name": _vm.receiverName },
              on: { changeSendMode: _vm.changeSendMode },
            }),
            _c("select-contact", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.mailData.singleFlag === 1,
                  expression: "mailData.singleFlag === 1",
                },
              ],
              ref: "cc",
              attrs: {
                "select-name": "抄送",
                "tag-style": {
                  color: "#3bc69a",
                  background: "rgba(38, 195, 147, 0.1)",
                },
              },
            }),
            _c("subject", {
              ref: "subject",
              model: {
                value: _vm.mailData.title,
                callback: function ($$v) {
                  _vm.$set(_vm.mailData, "title", $$v)
                },
                expression: "mailData.title",
              },
            }),
            _c("attach", {
              ref: "attach",
              model: {
                value: _vm.mailData.attachList,
                callback: function ($$v) {
                  _vm.$set(_vm.mailData, "attachList", $$v)
                },
                expression: "mailData.attachList",
              },
            }),
            _c("mail-editor", {
              ref: "mailEditor",
              attrs: {
                "should-add-seat": _vm.shouldAddSeat,
                "mail-detail": _vm.mailDetail,
                "mail-type": _vm.mailType,
              },
            }),
            _c("mail-footer", {
              ref: "mailFooter",
              attrs: {
                "has-approve-btn": _vm.mappingId !== undefined,
                "detail-send-user": _vm.detailSendUser,
                "receipt-flag": _vm.mailData.receiptFlag,
                "urgent-flag": _vm.mailData.urgentFlag,
              },
              on: {
                sendApproveMail: _vm.sendApproveMail,
                sendMail: _vm.sendMail,
              },
            }),
          ],
          1
        ),
      ]),
      _c("password-dialog", {
        ref: "passwordDialog",
        on: { submit: _vm.submit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }