var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visiable
    ? _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.edData && _vm.edData.id ? "编辑联系人" : "新增联系人",
            width: "700px",
            visible: _vm.visiable,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visiable = $event
            },
            close: _vm.closeDialog,
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "content-wrap",
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.form, "label-width": "60px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "姓名" } },
                    [
                      _c("el-input", {
                        attrs: { size: "medium", maxlength: "100" },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "邮箱" } },
                    [
                      _c("el-input", {
                        attrs: { size: "medium", maxlength: "100" },
                        model: {
                          value: _vm.form.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "address", $$v)
                          },
                          expression: "form.address",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属分组" } },
                    [
                      _c("en-select", {
                        attrs: {
                          placeholder: "请选择",
                          data: _vm.cfData,
                          props: _vm.defaultProps,
                          align: "left",
                          "data-mode": "value",
                          "allow-create": "",
                          "custom-option-add": "",
                        },
                        on: {
                          "custom-option-add-method":
                            _vm.onCustomOptionAddMethod,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "content",
                              fn: function ({ node }) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "sel-wrap" },
                                    [
                                      _c("en-icon", {
                                        attrs: {
                                          name: node.data.icon,
                                          size: "16",
                                          color: node.data.color,
                                        },
                                      }),
                                      _c("div", { staticClass: "sel-txt" }, [
                                        _vm._v(_vm._s(node.data.name)),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          425614251
                        ),
                        model: {
                          value: _vm.form.classRefId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "classRefId", $$v)
                          },
                          expression: "form.classRefId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("div", { staticClass: "btn-cls", on: { click: _vm.save } }, [
                _c("div", { staticClass: "btn-txt" }, [_vm._v("确定")]),
              ]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }