<template>
  <el-popover
    placement="bottom"
    width="200"
    trigger="hover"
    content="您上传的文件类型错误">
    <div slot="reference" class="other-card">
      <i class="el-icon-document-delete other"></i>
      <div>
        <p class="name">{{fileData.name}}</p>
        <p class="size">{{size}}</p>
      </div>
      <i class="el-icon-close" @click="deleteFile"></i>
      <el-progress :text-inside="true" class="progress" :stroke-width="26" v-if="fileData.percentage !== 100" :percentage="Math.floor(fileData.percentage)"></el-progress>
    </div>
  </el-popover>
</template>

<script>
import { bytesToSize } from "../../../assets/js/utils";

export default {
  name: "otherCard",
  props: {
    fileData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    size() {
      return bytesToSize(this.fileData.size);
    }
  },
  data() {
    return {};
  },
  methods: {
    deleteFile() {
      this.$emit("deleteFile");
    }
  }
};
</script>

<style lang="scss" scoped>
.other-card {
  position: relative;
  display: flex;
  width: 290px;
  height: 60px;
  border: 1px solid #f76d6d;
  background: #fbfbfb;
  border-radius: 4px;
  align-items: center;
  &:hover {
    background: #f5f8fc;
    .el-icon-close {
      display: block;
    }
  }
  .other {
    width: 40px !important;
    height: 40px !important;
    border-radius: 2px;
    margin: 0 10px;
    font-size: 40px;
  }
  .name {
    font-size: 12px;
    color: #636c78;
    margin-bottom: 10px;
  }
  .size {
    font-size: 12px;
    color: #91a1b7;
  }
  .el-icon-close {
    position: absolute;
    top: 6px;
    right: 6px;
    cursor: pointer;
    color: #f76d6d;
    display: none;
  }
  .progress {
    position: absolute;
    width: 100%;
  }
}
</style>
