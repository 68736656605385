var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: {
        placement: "bottom",
        width: "200",
        trigger: "hover",
        content: "您上传的文件类型错误",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "other-card",
          attrs: { slot: "reference" },
          slot: "reference",
        },
        [
          _c("i", { staticClass: "el-icon-document-delete other" }),
          _c("div", [
            _c("p", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm.fileData.name)),
            ]),
            _c("p", { staticClass: "size" }, [_vm._v(_vm._s(_vm.size))]),
          ]),
          _c("i", {
            staticClass: "el-icon-close",
            on: { click: _vm.deleteFile },
          }),
          _vm.fileData.percentage !== 100
            ? _c("el-progress", {
                staticClass: "progress",
                attrs: {
                  "text-inside": true,
                  "stroke-width": 26,
                  percentage: Math.floor(_vm.fileData.percentage),
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }