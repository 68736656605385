<template>
  <common-wrap>
    <div class="select-contact">
      <span class="select-contact-wrap" @click="choiceContact">
        <span class="select-name">{{selectName}}</span>
        <i class="iconfont icon-plus">&#xebd2;</i>
      </span>
      <input-tag v-model="contactList" ref="inputTag" :tag-style="tagStyle"></input-tag>
      <el-button type="text" style="font-size: 12px; color: #3e90fe;" v-if="canChangeMode" @click="changeMode">{{btnText}}</el-button>
    </div>
    <mail-list-dialog ref="mailListDialog" @confirmSelect="confirmSelect"></mail-list-dialog>
  </common-wrap>
</template>

<script>
import CommonWrap from "./CommonWrap.vue";
import MailListDialog from "./MailListDialog.vue";
import InputTag from "./InputTag/InputTag.vue";

export default {
  name: "SelectContact",
  components: {
    CommonWrap,
    MailListDialog,
    InputTag
  },
  props: {
    canChangeMode: {
      type: Boolean,
      default: false
    },
    selectName: {
      type: String,
      default: "收件人"
    },
    tagStyle: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      contactList: []
    };
  },
  computed: {
    btnText() {
      if (this.selectName === "收件人") {
        return "分别发送";
      }
      return "取消分别发送";
    }
  },
  watch: {
    "contactList.length": {
      handler() {
        this.$eventHub.$emit("editorResize");
      }
    }
  },
  methods: {
    focus() {
      this.$refs.inputTag.focusNewTag();
    },
    confirmSelect(list) {
      const formatList = list.map((item) => ({
        mailAccount: item.address,
        mailName: item.name,
        userId: item.id
      })).filter((item) => !this.contactList.find((contact) => contact.mailAccount === item.mailAccount));
      this.contactList = [...this.contactList, ...formatList];
    },
    changeMode() {
      this.$emit("changeSendMode");
    },
    choiceContact() {
      this.$refs.mailListDialog.show();
    }
  }
};
</script>

<style lang="scss" scope>
.select-contact {
  display: flex;
  align-items: center;
  .select-contact-wrap {
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
    .select-name {
      display: inline-block;
      margin-right: 6px;
      width: 46px;
      font-size: 12px;
      color: #333;
      text-align-last: justify;
    }
    .icon-plus {
      position: relative;
      top: 2px;
      font-size: 18px;
      color: #4895df;
    }
  }
}
</style>
