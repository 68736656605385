<template>
  <div class="mail-send-wrapper">
    <top-header :nav-name="navName" @backHandle="backHandle"></top-header>
    <div class="main-send-container">
      <div class="main-send-content">
        <select-contact ref="receiver" class="receiver" can-change-mode @changeSendMode="changeSendMode" :select-name="receiverName"></select-contact>
        <select-contact ref="cc" v-show="mailData.singleFlag === 1" select-name="抄送" :tag-style="{color: '#3bc69a', background: 'rgba(38, 195, 147, 0.1)'}"></select-contact>
        <subject v-model="mailData.title" ref="subject"></subject>
        <attach ref="attach" v-model="mailData.attachList"></attach>
        <mail-editor :should-add-seat="shouldAddSeat" ref="mailEditor" :mail-detail="mailDetail" :mail-type="mailType"></mail-editor>
        <mail-footer ref="mailFooter" :has-approve-btn="mappingId !== undefined" :detail-send-user="detailSendUser" @sendApproveMail="sendApproveMail"
          :receipt-flag="mailData.receiptFlag" :urgent-flag="mailData.urgentFlag" @sendMail="sendMail">
        </mail-footer>
      </div>
    </div>
    <!-- 更换密码弹窗 -->
    <password-dialog ref="passwordDialog"  @submit="submit"></password-dialog>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { mailSendService } from "@/api/mailBox/mailSend";
import { mailSettingService } from "@/api/mailBox/mailSetting";
import { mailBoxService } from "@/api/mailBox/mailList";
import { newTabAddBusinessData } from "@/components/businessDetail/helper";
import MailEditor from "../../components/MailSend/MailEditor.vue";
import SelectContact from "../../components/MailSend/SelectContact.vue";
import Subject from "../../components/MailSend/Subject.vue";
import Attach from "../../components/MailSend/Attach.vue";
import MailFooter from "../../components/MailSend/MailFooter.vue";
import PasswordDialog from "../../components/MailSetting/PasswordDialog.vue";
import TopHeader from "../../components/MailSend/TopHeader.vue";
import { getFileType } from "../../assets/js/utils";

export default {
  name: "MailSend",
  props: {
    mailId: {
      type: String
    },
    // 发送类型
    // send => 首发
    // reply => 回复
    // replyAll => 回复全部
    // replyWithAttach => 回复带附件
    // forward => 转发
    // business => 业务建模转过来的
    // draft => 草稿箱
    mailType: {
      type: String,
      default: "send"
    }
  },
  components: {
    TopHeader,
    MailEditor,
    SelectContact,
    Subject,
    Attach,
    MailFooter,
    PasswordDialog
  },
  data() {
    return {
      autoGraphContent: "",
      autoGraphReplyContent: "",
      navName: "新邮件",
      detailSendUser: {},
      mailDetail: {},
      mailData: {
        // 发送方式，1-暂存，2-发送， 3-审批发送
        operateType: 1,
        // 邮箱账户id
        mailAccountId: "",
        // 邮箱账户
        mailAccount: "",
        title: "",
        content: "",
        // 回执标记（0-是，1-否）
        urgentFlag: 1,
        // 紧急标记（0-是，1-否）
        receiptFlag: 1,
        // 单独发送标记（0-是，1-否）
        singleFlag: 1,
        // 审批发送的映射id
        mappingId: "",
        // 业务建模映射到邮件的，业务建模id
        mdDataId: "",
        // 必填 收件人列表
        receiverList: [],
        // 选填 抄送人列表
        ccList: [],
        attachList: [],
        // 关联类型（0-无，1-回复邮件，2-转发邮件）
        relatedType: 0,
        // 回复、转发时关联邮件id
        relatedMailId: undefined,
        id: undefined
      },
      shouldAddSeat: true,
      mappingId: undefined
    };
  },
  methods: {
    ...mapMutations("mailBox", [
      "setUploadAction"
    ]),
    async submit(data) {
      try {
        const res = await mailSettingService.updateMailAccountByGeneral(data);
        console.log(res);
        this.$refs.passwordDialog.hide();
        this.sendMail(this.mailData.operateType);
      } catch (error) {
        this.$refs.passwordDialog.btnLoading = false;
      }
    },
    async initMappingList() {
      const res = await mailBoxService.queryMailBtn();
      // type 1-邮件映射，2-邮件审批
      this.mappingId = res.find((item) => item.enable === "000" && item.type === 2)?.id;
      this.mappingTemplateId = res.find((item) => item.enable === "000" && item.type === 2)?.templateId;
    },
    sendApproveMail() {
       this.sendMail(3, { mappingId: this.mappingId });
      // if (this.validateForm(this.mailData)) {
      //   this.$refs.approveDialog.show();
      // }
    },
    backHandle() {
      if (this.$router.history._startLocation?.startsWith("/mailbox/mailSend")) {
        this.$router.push("/mailbox/list");
      } else {
        this.$router.back();
      }
    },
    fillAutoGraph() {
      const mailType = this.formatMailType;
      // 发送邮件填入签名
      if (mailType === "send" && this.autoGraphContent) {
        this.$refs.mailEditor.setContent(`<br/>${ this.autoGraphContent}`);
        this.shouldAddSeat = false;
      }
      // 回复/转发填入签名
      if (!["send", "draft"].includes(mailType) && this.autoGraphReplyContent) {
        this.$refs.mailEditor.setContent(`<br/>${ this.autoGraphReplyContent}`);
        this.shouldAddSeat = false;
      }
    },
    async queryMailSignList() {
      if (this.formatMailType === "business") {
        await this.fillbackBusinessMailInfo();
      }
      try {
        const res = await mailSettingService.queryMailSignList();
        this.autoGraphReplyContent = res?.find((item) => item.isReplyDefault)?.content;
        this.autoGraphContent = res?.find((item) => item.isDefault)?.content;
        this.fillAutoGraph();
      } catch (error) {
        console.log(error);
      }
      // 先填入签名
      if (this.mailId) {
        this.queryMailDetail();
      }
    },
    async queryUpladUrlParam() {
      const res = await mailSendService.queryUpladUrlParam();
      this.setUploadAction(`${res.server}?token=${res.token}&appKey=${res.appKey}&ts=${res.ts}`);
    },
    // 查询邮件详情
    async queryMailDetail() {
      const res = await mailSendService.queryMailDetail({
        id: this.mailId
      });
      const mailType = this.formatMailType;
      this.mailDetail = res;
      const {
        senderName, senderMail, title, attachList, urgentFlag, singleFlag, receiptFlag, mailAccountId, senderUserId
      } = this.mailDetail;

      // 回复邮件
      if (mailType === "reply") {
        this.navName = "回复邮件";
        this.mailData.title = `回复：${title}`;
        this.$refs.receiver.contactList = [{
          mailAccount: senderMail,
          mailName: senderName
        }];
        this.detailSendUser = {
          id: mailAccountId
        };
      }
      // 转发邮件
      if (mailType === "forward") {
        this.navName = "转发邮件";
        this.mailData.title = `转发：${title}`;
        this.fillbackAttach(attachList);
        this.focusInput();
        this.detailSendUser = {
          id: mailAccountId
        };
      }
      // 回复带附件 回复全部
      if (mailType === "replyWithAttach" || mailType === "replyAll") {
        this.navName = "回复邮件";
        this.mailData.title = `回复：${title}`;
        this.replyAll(this.mailDetail);
        if (mailType === "replyWithAttach") {
          this.fillbackAttach(attachList);
        }
        this.detailSendUser = {
          id: mailAccountId
        };
      }
      // 草稿箱进来
      if (mailType === "draft") {
        this.navName = "新邮件";
        this.mailData.title = title;
        this.mailData.urgentFlag = urgentFlag;
        this.mailData.singleFlag = singleFlag;
        this.mailData.receiptFlag = receiptFlag;
        this.replyAll(this.mailDetail, false);
        this.fillbackAttach(attachList);
        this.$refs.mailEditor.setContent(res.content);
        this.detailSendUser = {
          id: senderUserId
        };
      }
    },
    focusInput() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.receiver.focus();
        }, 500);
      });
    },
    fillbackAttach(attachList) {
      this.mailData.attachList = attachList.map((item) => ({
        ...item,
        size: item.attachmentSize || item.fileSize,
        name: item.name || item.fileName,
        percentage: 100
      }));
    },
    replyAll({
      receiverList, ccList, senderMail, senderName
    }, includeReplyUser = true) {
      this.$refs.receiver.contactList = [...receiverList.map((item) => ({
        mailAccount: item.mail,
        mailName: item.name,
        userId: item.userId
      })).filter((item) => item.mailAccount !== this.mailDetail.mailAccount || !includeReplyUser)];
      if (includeReplyUser) {
        this.$refs.receiver.contactList.push({
          mailAccount: senderMail,
          mailName: senderName
        });
      }
      this.$refs.cc.contactList = ccList.map((item) => ({
        mailAccount: item.mail,
        mailName: item.name,
        userId: item.userId
      }));
    },
    changeSendMode() {
      const { singleFlag } = this.mailData;
      this.mailData.singleFlag = singleFlag === 0 ? 1 : 0;
    },
    async determineSendMail(mailData) {
      this.$refs.mailFooter.btnLoading = true;
      if (this.notifyInstance) {
        this.notifyInstance.close();
        this.notifyInstance = null;
      }
      // 是业务建模过来的 不需要 删除mdDataId 其他情况mdDataId为空
      if (this.mailType !== "business") {
        mailData.mdDataId = "";
      }
      try {
        const res = await mailSendService.sendMail(mailData);
        if (res.errorMailAccountList?.length) {
          // 密码错误
          this.$refs.passwordDialog.show(res.errorMailAccountList[0]);
          this.$refs.mailFooter.btnLoading = false;
          return;
        }
        const closeWindow = () => {
          // 如果从业务建模或我的文件夹过来的情况 发送后关闭页面 zjh
          const { doc } = this.$route.query;
          if (this.mailType === "business" || doc || doc === "") {
            window.close();
          }
        };
        if (res && res.errorMappingId) {
          // const url = `/toMdModelUserFill.do?templateId=${this.mappingTemplateId}&mailId=${res.id}&mailBtnId=${this.mappingId}&flg=add&type=${res.templateType}`;
          // window.open(url);
          const route = newTabAddBusinessData({
            businessType: res.templateType,
            templateId: this.mappingTemplateId,
            mailBtnId: this.mappingId,
            mailId: res.id
          });
          this.backHandle();
          const mailRouter = this.$router.resolve(route);
          window.open(mailRouter.href, "_black");
          // closeWindow();
          return;
        }
        this.$refs.mailFooter.btnLoading = false;
        if (mailData.operateType === 2 || mailData.operateType === 3) {
          this.$message.success("发送成功");
          closeWindow();
          this.backHandle();
        } else {
          Object.assign(this.mailData, res);
          this.$message.success("草稿保存成功");
        }
      } catch (error) {
        this.$refs.mailFooter.btnLoading = false;
      }
    },
    validateForm(mailData) {
      // if (this.$refs.attach.isOverSize) {
      //   this.$message.warning("所有附件的总和须小于14M");
      //   return;
      // }
      if (!mailData.receiverList.length && !mailData.ccList.length) {
        this.$message.warning("收件人和抄送不能同时为空");
        return false;
      }
      if (mailData.attachList.some((item) => item.percentage !== 100)) {
        const h = this.$createElement;
        if (this.notifyInstance) {
          this.notifyInstance.close();
        }
        this.notifyInstance = this.$notify({
          title: "正在上传附件",
          duration: 0,
          message: h("div", { style: "color: #333; padding: 10px 0;" }, ["上传完成后将自动发送 ", h("span", {
              style: "color: #4895df; cursor: pointer;",
              on: {
                click: () => {
                  this.notifyInstance = null;
                  this.notifyInstance.close();
                }
              }
            }, "[取消自动发送]")
          ])
        });
        return false;
      }
      return true;
    },
    // type 1 => 存草稿 2 => 发送邮件 3 => 审批发送
    async sendMail(type, approveData = {}) {
      this.mailData.operateType = type;
      const mailData = { ...this.mailData };
      Object.assign(mailData, approveData);
      const { currentUser, urgentChecked, receiptChecked } = this.$refs.mailFooter;
      const { id, mailFullAccount } = currentUser;
      mailData.mailAccountId = id;
      mailData.mailAccount = mailFullAccount;
      mailData.urgentFlag = urgentChecked ? 0 : 1;
      mailData.receiptFlag = receiptChecked ? 0 : 1;
      mailData.mdDataId = this.mailDetail.mdDataList?.find((item) => item.type === 2)?.id || this.$route.query.id;
      const mailTypeMap = {
        send: 0,
        reply: 1,
        replyAll: 1,
        replyWithAttach: 1,
        forward: 2
      };
      if (type === 2) {
        mailData.relatedType = mailTypeMap[this.formatMailType];
        if (mailData.relatedType === 1 || mailData.relatedType === 2) {
          mailData.relatedMailId = this.mailDetail.id;
        }
      }
      if (this.mailType === "draft" || type === 1) {
        mailData.id = this.mailDetail.id || mailData.id;
      }
      mailData.content = this.$refs.mailEditor.getEditorData();
      mailData.receiverList = JSON.stringify(this.$refs.receiver.contactList.map((item) => ({
        name: item.mailName,
        mail: item.mailAccount,
        userId: item.mailUserId
      })));
      if (mailData.singleFlag === 1) {
        mailData.ccList = JSON.stringify(this.$refs.cc.contactList.map((item) => ({
          name: item.mailName,
          mail: item.mailAccount,
          userId: item.mailUserId
        })));
      }
      // if (this.$refs.attach.isOverSize) {
      //   this.$message.warning("所有附件的总和须小于14M");
      //   return;
      // }
      if (!JSON.parse(mailData.receiverList).length && type !== 1) {
        this.$message.warning("请填写收件人");
        this.focusInput();
        return;
      }
      if (!this.$refs.mailEditor.getPureEditorData() && type !== 1) {
        this.$message.warning("请填写正文");
        return;
      }
      if (mailData.attachList.some((item) => item.percentage !== 100)) {
        const h = this.$createElement;
        if (this.notifyInstance) {
          this.notifyInstance.close();
        }
        this.notifyInstance = this.$notify({
          title: "正在上传附件",
          duration: 0,
          message: h("div", { style: "color: #333; padding: 10px 0;" }, ["上传完成后将自动发送 ", h("span", {
              style: "color: #4895df; cursor: pointer;",
              on: {
                click: () => {
                  this.notifyInstance = null;
                  this.notifyInstance.close();
                }
              }
            }, "[取消自动发送]")
          ])
        });
        return;
      }
      mailData.attachList = JSON.stringify(
        mailData.attachList.filter((file) => !file.response || file.response.code === "0").map((file) => ({
          name: file.name || file.fileName,
          extension: file.name.split(".")[1],
          attachmentSize: file.size,
          type: getFileType(file) === "image" ? "000" : "001",
          code: file.url || file.code || file.fileUrl || file.response?.data.fileCode,
          fileHash: file.fileHash
        }))
      );
      this.determineSendMail(mailData);
    },
    async fillbackContact() {
      const { c: componyIds, p: personalIds } = this.$route.query;
      const promises = [];
      if (componyIds) {
        promises.push(
          mailSendService.getCompanyContactsByIds({ ids: componyIds })
        );
      }
      if (personalIds) {
        promises.push(
          mailSendService.getPersonalContactsByIds({ ids: personalIds })
        );
      }
      if (componyIds || personalIds) {
        const res = await Promise.all(promises);
        this.$refs.receiver.contactList = res.flat().map((item) => ({
          mailAccount: item.address,
          mailName: item.name,
          userId: item.userId
        }));
      }
    },
    async fillbackAttachList() {
      const { doc: fileIds } = this.$route.query;
      if (fileIds) {
        const res = await mailSendService.fiGetFileInfoByIds({ fileIds });
        this.fillbackAttach(res);
      }
    },
    async fillbackBusinessMailInfo() {
      const { id: recordId, templateId, btnKey } = this.$route.query;
      const {
        content, attachList, title, receiverList, ccList, urgentFlag, singleFlag
      } = await mailSendService.mdGenerateMailInfo({
        recordId, templateId, btnKey
      });
      this.mailData.singleFlag = singleFlag !== undefined ? singleFlag : 1;
      this.mailData.urgentFlag = urgentFlag;
      this.$refs.mailEditor.setContent(content);
      this.$refs.receiver.contactList = receiverList.map((item) => ({
        mailAccount: item.mail,
        mailName: item.name,
        userId: item.userId
      }));
      this.$refs.cc.contactList = ccList.map((item) => ({
        mailAccount: item.mail,
        mailName: item.name,
        userId: item.userId
      }));
      this.mailData.title = title;
      this.fillbackAttach(attachList);
      return Promise.resolve();
    }
  },
  created() {
    // 集成了获取详情
    this.queryMailSignList();
  },
  mounted() {
    if (!this.mailId) {
      this.mailData.receiptFlag = this.userMailConfig.mailNeedReceipt === "0" ? 0 : 1;
      this.focusInput();
    }
    // if (this.formatMailType === "business") {
    //   this.fillbackBusinessMailInfo();
    // }
    this.fillbackContact();
    this.fillbackAttachList();
    this.$eventHub.$on("uploadSuccess", ({ name }) => {
      if (!this.mailData.title) {
        this.mailData.title = name;
      }
      // 上传文件成功 继续发送文件
      if (this.notifyInstance) {
        this.sendMail(this.mailData.operateType);
      }
    });
    this.queryUpladUrlParam();
    this.initMappingList();
  },
  destroyed() {
    this.$eventHub.$off("uploadSuccess");
    this.$eventHub.$off("editorResize");
  },
  watch: {
    userMailConfig: {
      handler(newVal) {
        this.mailData.receiptFlag = newVal.mailNeedReceipt === "0" ? 0 : 1;
      }
    },
    "mailData.singleFlag": {
      handler() {
        this.$eventHub.$emit("editorResize");
      }
    }
  },
  computed: {
    ...mapGetters("mailBox", [
      "userMailConfig"
    ]),
    formatMailType() {
       const mailTypeMap = {
        1: "reply",
        2: "forward",
        3: "replyAll",
        4: "replyWithAttach",
        5: "draft"
      };
      return mailTypeMap[this.mailType] || this.mailType;
    },
    receiverName() {
      return this.mailData.singleFlag === 0 ? "分送给" : "收件人";
    }
  }
};
</script>

<style lang="scss" scope>
.mail-send-wrapper {
  height: 100%;
  background: #ecf1f7;
  .main-send-container {
    padding: 8px;
    .main-send-content {
      background: #fff;
    }
    .receiver {
      .vue-input-tag-wrapper {
        z-index: 5;
      }
    }
  }
}
</style>
