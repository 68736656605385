/*
 * @Author: zoujp
 * @Date: 2021-02-26 09:26:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-03-05 17:41:04
 * @Description: file content
 */
import BaseService from "../../BaseService";

class AddressBookService extends BaseService {
  pk = "";

  constructor(http) {
    super("", http);
  }

  getMenuList(params) {
    return this.http.post("queryObjectTreeExtAccess", params);
  }

  deleteContact(params) {
    return this.http.post("deleteContact", params);
  }

  getMenuClassify(params) {
    return this.http.post("getContactClassifies", params);
  }

  modifyContactClassify(params) {
    return this.http.post("modifyContactClassify", params);
  }

  addContactClassify(params) {
    return this.http.post("addContactClassify", params);
  }

  addContact(params) {
    return this.http.post("addContact", params);
  }

  deleteContactClassify(params) {
    return this.http.post("deleteContactClassify", params);
  }

  getMailAccount(params) {
    return this.http.post("deleteContactClassify", params);
  }

  searchContact(params) {
    return this.http.post("searchContact", params);
  }

  searchCompanyContact(params) {
    return this.http.post("searchCompanyContact", params);
  }

  getContactList(params) {
    return this.http.post("getContactList", params);
  }

  modifyContact(params) {
    return this.http.post("modifyContact", params);
  }
}

export default AddressBookService;
