var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ab-top" }, [
    _c("div", { staticClass: "ab-top-inner" }, [
      _c(
        "div",
        { staticClass: "tit" },
        [
          _c("enIcon", {
            attrs: { title: "返回", name: "fanhui" },
            nativeOn: {
              click: function ($event) {
                return _vm.back.apply(null, arguments)
              },
            },
          }),
          _c("div", { staticClass: "txts" }, [
            _vm._v(_vm._s(_vm.sourceType === 3 ? "全局搜索" : "通讯录")),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search" },
        [
          _c("enIcon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.searchFlag === 1,
                expression: "searchFlag === 1",
              },
            ],
            attrs: { title: "搜索", name: "sousuo" },
            nativeOn: {
              click: function ($event) {
                return _vm.search.apply(null, arguments)
              },
            },
          }),
          _c("en-search", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.searchFlag === 2,
                expression: "searchFlag === 2",
              },
            ],
            ref: "searchIpt",
            attrs: { showBorder: true },
            on: { search: _vm.searchSure },
            model: {
              value: _vm.searchValue,
              callback: function ($$v) {
                _vm.searchValue = $$v
              },
              expression: "searchValue",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }