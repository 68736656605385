<!--
 * @Author: zoujp
 * @Date: 2021-03-01 16:37:28
 * @LastEditTime: 2021-04-26 09:11:44
 * @LastEditors: Please set LastEditors
 * @Description: 通讯录-左侧
 * @FilePath: \mailbox\src\views\AddressBook\index.vue
-->
<template>
  <div class="ab-left">
    <div class="tit">
      <div class="txts">通讯信息</div>
    </div>
    <div class="content">
      <div class="mod-company">
        <div class="txts">公司通讯录</div>
        <div class="book-tree">
          <collapse-tree v-for="(itm, idx) in mList" :key="idx" :node="itm"></collapse-tree>
        </div>
      </div>
      <div class="mod-group">
        <div class="txts">个人通讯录</div>
        <div class="book-group">
          <div :class="['temp', itm.cur ? 'cur' : '']" v-for="(itm, idx) in cList" :key="idx" @click="handleClick(itm)">
            <en-icon :name="itm.icon" :color="itm.color" size="mini"></en-icon>
            <div class="name">{{ itm.name }}</div>
            <div class="handle-wrap">
              <enIcon title="" name="bianji1" size="mini"></enIcon>
              <div class="handle-lis">
                <ul>
                  <li @click.stop="handleEdit(itm)"><span>编辑</span></li>
                  <li @click.stop="handleDel(itm)" v-show="cList.length!==1"><span>删除</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { addressBookService } from "@/api/mailBox/addressBook";
import collapseTree from "../../components/collapseTree";
export default {
  name: "AbLeft",
  components: { collapseTree },
  inject: ["$home"],
  computed: {},
  props: {
    menuList: {
      type: Array,
      default: () => []
    },
    classify: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      mList: this.menuList,
      cList: this.classify
    };
  },
  watch: {
    menuList(val) {
      this.mList = val;
    },
    classify(val) {
      this.cList = val;
    }
  },
  mounted() {},
  methods: {
    handleClick(obj) {
      this.$home.clickClassify(obj);
    },
    handleEdit(obj) {
      this.$home.editClassify(obj);
    },
    handleDel(obj) {
      const _this = this;
      const h = this.$createElement;
      this.$msgbox({
        title: "",
        closeOnClickModal: false,
        message: h("div", { style: { display: "flex", alignItems: "center", fontSize: "12px" } }, [
          h("en-icon", { attrs: { name: "tishi", color: "#F76B6A" } }),
          h("div", { style: { marginLeft: "10px" } }, [ h("p", {}, "确定删除该分组？") ] )
        ]),
        confirmButtonClass: "mail-btn-error",
        async callback(action) {
          if (action === "confirm") {
            _this.deleteSure(obj.id);
          }
        }
      });
    },
    async deleteSure(id) {
      try {
        const params = {
          userId: this.$store.state.userInfo.userId,
          id
        };
        await addressBookService.deleteContactClassify(params);
        this.$message({
          message: "删除成功",
          type: "success"
        });
        this.$home.delClassifyCallback(id);
      } catch (error) {
        console.log("error");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.ab-left {
  width: 336px;
  height: 100%;
  background-color: #fff;
  border-radius: 4px;
  margin-right: 8px;
  .tit {
    padding: 0 20px;
    border-bottom: 1px solid #e8ebf2;
    > .txts {
      font-size: 14px !important;
    }
  }
  .txts {
    height: 50px;
    line-height: 50px;
    font-weight: bold;
    font-size: 12px;
    color: #333;
  }
  .content {
    height: calc(100% - 50px);
    overflow-y: auto;
    padding: 0 20px;
    .mod-company {
      .book-tree {
        border: 1px solid #e8ecf2;
        // border-top: 0;
        border-radius: 4px;
      }
    }
    .mod-group {
      padding-bottom: 50px;
      .temp {
        height: 36px;
        line-height: 36px;
        margin-bottom: 10px;
        border: 1px solid #e8ecf2;
        border-radius: 4px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 10px;
        position: relative;
        .name {
          font-size: 12px;
          color: #636c78;
          margin-left: 5px;
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .handle-wrap {
          display: none;
          > .en-icon {
            color: #3e90fe;
          }
          &:hover {
            .handle-lis {
              display: block;
            }
          }
          .handle-lis {
            display: none;
            position: absolute;
            right: 0;
            top: 20px;
            z-index: 99;
            > ul {
              border-radius: 5px;
              border: 1px solid #e8e8e8;
              width: 120px;
              overflow: hidden;
              list-style: none;
              padding: 0;
              margin-top: 0;
              margin-bottom: 10px;
              > li {
                height: 32px;
                line-height: 32px;
                padding: 0 10px;
                color: #606060;
                font-size: 12px;
                background: #fff;
                list-style: none;
                &:hover {
                  background: #3e90fe;
                  color: #fff;
                }
              }
            }
          }
        }
        &:hover {
          border-color: #3e90fe;
          .handle-wrap {
            display: block;
            cursor: pointer;
          }
        }
        &.cur {
          background: #3e90fe;
          .en-icon {
            color: #fff !important;
          }
          .name {
            color: #fff !important;
          }
        }
      }
    }
  }
}
</style>
