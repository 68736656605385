<template>
  <el-dialog
    title=""
    custom-class="folder-dialog"
    append-to-body
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="1100px"
  >
    <span slot="title">我的文件夹</span>
    <div class="folder">
      <div class="left-wrapper">
        <en-file-select-board
          :data="treeData"
          node-key="id"
          highlight-current
          accordion
          expand-icon="wendangleixing-wendangguanli"
          collpase-icon="wendangleixing-wendangguanli"
          :props="defaultProps"
          @node-select="singleClick"
          @check="singleClick"
          @node-click="singleClick"
        >
        </en-file-select-board>
      </div>
      <div class="right-wrapper" v-loading="loading">
        <el-input
          placeholder="请输入"
          suffix-icon="el-icon-search"
          v-model="searchText">
        </el-input>
        <ul class="folder-list" v-if="folderList.length">
          <li class="folder-item" v-for="item in folderList" :key="item.id">
            <el-checkbox v-model="item.checked"></el-checkbox>
            <div class="content-item" @click="item.checked = !item.checked">
              <span class="foloder-info">
                <en-icon
                  :name="suffix(item.fileName).iconName"
                  :color="suffix(item.fileName).iconColor"
                  size="small"
                ></en-icon>
                {{ item.fileName }}
              </span>
              <span style="margin-right: 10px;">
                {{parseFloat( item.attachmentSize / 1024 / 1024).toFixed(2) + "M"}}
              </span>
              <span>{{item.createTime}}</span>
            </div>
          </li>
          <div class="loadmore" v-show="folderData.total - folderList.length > 0">
            <span @click="loadMore">加载更多  ({{folderData.total - folderList.length}})</span>
          </div>
        </ul>
        <div class="no-data" v-else-if="!loading">
           <img
              class="icon-img"
              src="https://images.softfuxi.com/static/nodata11310.png"
              alt="无数据"
            />
            <div>暂无数据</div>
        </div>
      </div>
    </div>
    <span slot="footer">
      <div class="footer">
        <span class="select-count">
          已选中 <span style="color: #3e90fe;">{{selectedList.length}}</span> 项
        </span>
        <en-button type="primary" style="font-size: 12px;" size="medium" @click="confirmSelect">确 定</en-button>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { mailSendService } from "@/api/mailBox/mailSend";
import { debounce } from "../../assets/js/utils";

export default {
  name: "FolderDialog",
  components: {
  },
  data() {
    return {
      btnLoading: false,
      loading: false,
      dialogVisible: false,
      filterText: "",
      treeData: [],
      folderList: [],
      folderData: {
        records: [],
        pageSize: 10,
        pageNo: 1
      },
      searchText: "",
      defaultProps: {
        children: "nodes",
        label: "text",
        pageSize: 15
      }
    };
  },
  computed: {
    selectedList() {
      return this.folderList.filter((item) => item.checked);
    }
  },
  watch: {},
  mounted() {
    this.queryFolderList();
    this.fiQueryMyFolderFiles();
    this.$watch("searchText", debounce(this.fiQueryMyFolderFiles, 300));
  },
  methods: {
    async queryFolderList() {
      this.treeData = await mailSendService.queryFolderList();
    },
    loadMore() {
      this.folderData.pageNo += 1;
      this.fiQueryMyFolderFiles();
    },
    async fiQueryMyFolderFiles(refresh) {
      this.loading = true;
      const { folderData, folderId, searchText } = this;
      if (refresh !== undefined) {
        folderData.pageNo = 1;
        folderData.pageSize = 10;
        folderData.total = 0;
      }
      const { pageNo, pageSize } = folderData;
      const params = { pageSize, pageNo };
      params.folderId = folderId || "";
      params.searchText = searchText;
      params.sourceType = 1;
      const res = await mailSendService.fiQueryMyFolderFiles(params);
      this.loading = false;
      if (refresh !== undefined) {
        this.folderList = [];
      }
      res.records.forEach((item) => {
        this.$set(item, "checked", false);
      });
      // 过滤掉业务建模带来的zip
      this.folderList = [...this.folderList, ...res.records];
      this.folderData = res;
    },
    singleClick({ id }) {
      this.folderId = id;
      this.searchText = "";
      this.fiQueryMyFolderFiles(true);
    },
    show() {
      this.dialogVisible = true;
    },
    hide() {
      this.dialogVisible = false;
    },
    confirmSelect() {
      this.hide();
      this.$emit("confirmSelect", this.selectedList);
    },
      /**
     * @description: 获取文件后缀名
     * @param {type}
     * @return:
     */
    getSuffix(filename) {
      if (!filename || typeof filename !== "string") {
        return false;
      }
      const a = filename.split("").reverse().join("");
      return a.substring(0, a.search(/\./)).split("").reverse().join("");
    },
    /**
     * @description: 根据后缀给相应icon和颜色
     * @param {arr, suffix}
     * @return:
     */
    findIconType(arr, suffix) {
      return arr.find((el) => el.text === suffix);
    },
    /**
     * @description: 根据后缀给相应icon
     * @param {name}
     * @return:
     */
    suffix(name) {
      const suffixName = this.getSuffix(name);
      const fileList = [
        {
          value: "1", text: "gif", iconName: "fujian-tupian", iconColor: "#f7bf27"
        },
        {
          value: "2", text: "jpg", iconName: "fujian-tupian", iconColor: "#f7bf27"
        },
        {
          value: "3", text: "jpeg", iconName: "fujian-tupian", iconColor: "#f7bf27"
        },
        {
          value: "4", text: "bmp", iconName: "fujian-tupian", iconColor: "#f7bf27"
        },
        {
          value: "5", text: "png", iconName: "fujian-tupian", iconColor: "#f7bf27"
        },
        {
          value: "6", text: "pdf", iconName: "fujian-pdf", iconColor: "#f76b6e"
        },
        {
          value: "7", text: "doc", iconName: "fujian-Word", iconColor: "#4794e2"
        },
        {
          value: "8", text: "txt", iconName: "fujian-txt", iconColor: "#4794e2"
        },
        {
          value: "9", text: "xls", iconName: "fujian-excel", iconColor: "#57cc7d"
        },
        {
          value: "10", text: "xlsx", iconName: "fujian-excel", iconColor: "#57cc7d"
        },
        {
          value: "11", text: "ppt", iconName: "fujian-PPT", iconColor: "#f7c028"
        },
        {
          value: "12", text: "pptx", iconName: "fujian-PPT", iconColor: "#f7c028"
        },
        {
          value: "13", text: "vsd", iconName: "fujian-qita", iconColor: "#f7bf27"
        },
        {
          value: "14", text: "wps", iconName: "fujian-qita", iconColor: "#f7bf27"
        },
        {
          value: "15", text: "rar", iconName: "fujian-yasuobao", iconColor: "#4595de"
        },
        {
          value: "16", text: "zip", iconName: "fujian-yasuobao", iconColor: "#4595de"
        },
        {
          value: "17", text: "tar", iconName: "fujian-yasuobao", iconColor: "#4595de"
        },
        {
          value: "18", text: "gzip", iconName: "fujian-yasuobao", iconColor: "#4595de"
        },
        {
          value: "19", text: "docx", iconName: "fujian-Word", iconColor: "#4794e2"
        }
      ];
      return this.findIconType(fileList, suffixName);
    }
  }
};
</script>
<style lang="scss">
.folder-dialog {
  .el-dialog__header {
    line-height: 1;
  }
  .el-dialog__body {
    padding-top: 10px;
    padding-bottom: 0;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .select-count {
      font-size: 12px;
      color: #666;
    }
  }
}
</style>
<style lang="scss" scoped>
.folder-dialog {
  .folder {
    display: flex;
    height: 530px;
    .left-wrapper, .right-wrapper {
      height: 100%;
      box-sizing: border-box;
      border: 1px solid #e8ecf2;
      border-radius: 4px;
      overflow-y: auto;
    }
    .left-wrapper {
      width: 280px;
      margin-right: 10px;
    }
    .right-wrapper {
      flex: 1;
      padding: 10px 20px;
      .folder-list {
        overflow: auto;
        margin-top: 10px;
        height: 465px;
        padding: 0;
        margin: 0;
        .folder-item {
          display: flex;
          font-size: 12px;
          align-items: center;
          cursor: pointer;
          color: #636c78;
          height: 34px;
          line-height: 34px;
          margin-bottom: 14px;
          .content-item {
            background: #f5f8fd;
            width: 100%;
            margin-left: 10px;
            .foloder-info {
              display: inline-block;
              width: 520px;
              text-indent: 10px;
              .en-icon {
                position: relative;
                top: 4px;
              }
            }
          }
        }
        .loadmore {
          text-align: center;
          color: #999;
          font-size: 12px;
          span {
            cursor: pointer;
          }
        }
      }
      .no-data {
        text-align: center;
        font-size: 12px;
        color: #999;
        margin-top: 100px;
      }
    }
    .center-wrapper {
      width: 84px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
