var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "common-wrap",
    [
      _c(
        "div",
        { staticClass: "select-contact" },
        [
          _c(
            "span",
            {
              staticClass: "select-contact-wrap",
              on: { click: _vm.choiceContact },
            },
            [
              _c("span", { staticClass: "select-name" }, [
                _vm._v(_vm._s(_vm.selectName)),
              ]),
              _c("i", { staticClass: "iconfont icon-plus" }, [_vm._v("")]),
            ]
          ),
          _c("input-tag", {
            ref: "inputTag",
            attrs: { "tag-style": _vm.tagStyle },
            model: {
              value: _vm.contactList,
              callback: function ($$v) {
                _vm.contactList = $$v
              },
              expression: "contactList",
            },
          }),
          _vm.canChangeMode
            ? _c(
                "el-button",
                {
                  staticStyle: { "font-size": "12px", color: "#3e90fe" },
                  attrs: { type: "text" },
                  on: { click: _vm.changeMode },
                },
                [_vm._v(_vm._s(_vm.btnText))]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("mail-list-dialog", {
        ref: "mailListDialog",
        on: { confirmSelect: _vm.confirmSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }