var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "",
        "custom-class": "folder-dialog",
        "append-to-body": "",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        width: "1100px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
        _vm._v("我的文件夹"),
      ]),
      _c("div", { staticClass: "folder" }, [
        _c(
          "div",
          { staticClass: "left-wrapper" },
          [
            _c("en-file-select-board", {
              attrs: {
                data: _vm.treeData,
                "node-key": "id",
                "highlight-current": "",
                accordion: "",
                "expand-icon": "wendangleixing-wendangguanli",
                "collpase-icon": "wendangleixing-wendangguanli",
                props: _vm.defaultProps,
              },
              on: {
                "node-select": _vm.singleClick,
                check: _vm.singleClick,
                "node-click": _vm.singleClick,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "right-wrapper",
          },
          [
            _c("el-input", {
              attrs: { placeholder: "请输入", "suffix-icon": "el-icon-search" },
              model: {
                value: _vm.searchText,
                callback: function ($$v) {
                  _vm.searchText = $$v
                },
                expression: "searchText",
              },
            }),
            _vm.folderList.length
              ? _c(
                  "ul",
                  { staticClass: "folder-list" },
                  [
                    _vm._l(_vm.folderList, function (item) {
                      return _c(
                        "li",
                        { key: item.id, staticClass: "folder-item" },
                        [
                          _c("el-checkbox", {
                            model: {
                              value: item.checked,
                              callback: function ($$v) {
                                _vm.$set(item, "checked", $$v)
                              },
                              expression: "item.checked",
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "content-item",
                              on: {
                                click: function ($event) {
                                  item.checked = !item.checked
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "foloder-info" },
                                [
                                  _c("en-icon", {
                                    attrs: {
                                      name: _vm.suffix(item.fileName).iconName,
                                      color: _vm.suffix(item.fileName)
                                        .iconColor,
                                      size: "small",
                                    },
                                  }),
                                  _vm._v(" " + _vm._s(item.fileName) + " "),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                { staticStyle: { "margin-right": "10px" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        parseFloat(
                                          item.attachmentSize / 1024 / 1024
                                        ).toFixed(2) + "M"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("span", [_vm._v(_vm._s(item.createTime))]),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.folderData.total - _vm.folderList.length > 0,
                            expression:
                              "folderData.total - folderList.length > 0",
                          },
                        ],
                        staticClass: "loadmore",
                      },
                      [
                        _c("span", { on: { click: _vm.loadMore } }, [
                          _vm._v(
                            "加载更多 (" +
                              _vm._s(
                                _vm.folderData.total - _vm.folderList.length
                              ) +
                              ")"
                          ),
                        ]),
                      ]
                    ),
                  ],
                  2
                )
              : !_vm.loading
              ? _c("div", { staticClass: "no-data" }, [
                  _c("img", {
                    staticClass: "icon-img",
                    attrs: {
                      src: "https://images.softfuxi.com/static/nodata11310.png",
                      alt: "无数据",
                    },
                  }),
                  _c("div", [_vm._v("暂无数据")]),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("span", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "div",
          { staticClass: "footer" },
          [
            _c("span", { staticClass: "select-count" }, [
              _vm._v(" 已选中 "),
              _c("span", { staticStyle: { color: "#3e90fe" } }, [
                _vm._v(_vm._s(_vm.selectedList.length)),
              ]),
              _vm._v(" 项 "),
            ]),
            _c(
              "en-button",
              {
                staticStyle: { "font-size": "12px" },
                attrs: { type: "primary", size: "medium" },
                on: { click: _vm.confirmSelect },
              },
              [_vm._v("确 定")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }