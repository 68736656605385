<template>
  <common-wrap>
    <div class="subject-wrap">
      <span class="name-wrap">
        <span class="name">主题</span>
      </span>
      <el-input ref="elInput" maxlength="50" v-model="inputValue"></el-input>
    </div>
  </common-wrap>
</template>

<script>
import CommonWrap from "./CommonWrap.vue";

export default {
  name: "Subject",
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      inputValue: this.value
    };
  },
  methods: {
    focus() {
      this.$refs.elInput.focus();
    }
  },
  watch: {
    value(val) {
      this.inputValue = val;
    },
    inputValue(val) {
      this.$emit("input", val);
    }
  },
  components: {
    CommonWrap
  }
};
</script>

<style lang="scss" scoped>
.subject-wrap {
  display: flex;
  align-items: center;
  /deep/ .el-input__inner {
    margin-left: 8px;
    border: none;
    color: #333;
    font-size: 12px;
    font-weight: bold;
  }
  .name-wrap {
    display: inline-block;
    margin-right: 10px;
    .name {
      display: inline-block;
      margin-right: 6px;
      width: 46px;
      font-size: 12px;
      color: #333;
      text-align-last: justify;
    }
  }
}
</style>
