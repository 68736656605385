var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mail-footer" }, [
    _c(
      "div",
      [
        _c(
          "el-popover",
          {
            attrs: {
              placement: "top-start",
              "popper-class": "mail-popover",
              trigger: "click",
            },
          },
          [
            _c(
              "div",
              { staticClass: "user-list" },
              _vm._l(_vm.userList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "user-item",
                    class: { "active-user-item": _vm.activeIndex === index },
                    on: {
                      click: function ($event) {
                        _vm.activeIndex = index
                      },
                    },
                  },
                  [
                    _c(
                      "el-avatar",
                      {
                        staticStyle: { "margin-right": "10px" },
                        style: { background: _vm.bgColor(index) },
                        attrs: { size: 26 },
                      },
                      [_vm._v(" " + _vm._s(item.name[0]) + " ")]
                    ),
                    _c("div", { staticClass: "user-info" }, [
                      _c("span", [_vm._v(_vm._s(item.name))]),
                      _c("span", [
                        _vm._v(" (" + _vm._s(item.mailFullAccount) + ")"),
                      ]),
                    ]),
                  ],
                  1
                )
              }),
              0
            ),
            _c(
              "span",
              {
                staticStyle: { cursor: "pointer" },
                attrs: { slot: "reference" },
                slot: "reference",
              },
              [
                _c("span", { staticClass: "user" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.currentUser.name || _vm.detailSendUser.name || "--"
                      ) +
                      " (" +
                      _vm._s(
                        _vm.currentUser.mailFullAccount ||
                          _vm.detailSendUser.mailFullAccount ||
                          "--"
                      ) +
                      ") "
                  ),
                ]),
                _c("i", { staticClass: "el-icon-arrow-down" }),
              ]
            ),
          ]
        ),
        _c("span", { staticClass: "division" }),
        _c(
          "el-popover",
          {
            attrs: {
              placement: "top-start",
              "popper-class": "operation-popover",
              trigger: "click",
            },
          },
          [
            _c("div", { staticClass: "operation-list" }, [
              _c(
                "div",
                { staticClass: "operation-item" },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.urgentChecked,
                        callback: function ($$v) {
                          _vm.urgentChecked = $$v
                        },
                        expression: "urgentChecked",
                      },
                    },
                    [_vm._v("紧急")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "operation-item" },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.receiptChecked,
                        callback: function ($$v) {
                          _vm.receiptChecked = $$v
                        },
                        expression: "receiptChecked",
                      },
                    },
                    [_vm._v("回执")]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "span",
              {
                staticStyle: { cursor: "pointer" },
                attrs: { slot: "reference" },
                slot: "reference",
              },
              [
                _c("span", { staticClass: "operation-text" }, [
                  _vm._v("更多信息"),
                ]),
                _c("i", { staticClass: "iconfont icon-more" }, [_vm._v("")]),
              ]
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "el-button",
          {
            staticClass: "mail-btn",
            attrs: { size: "small", loading: _vm.btnLoading },
            on: {
              click: function ($event) {
                return _vm.sendMail(1)
              },
            },
          },
          [_vm._v("存草稿")]
        ),
        _vm.hasApproveBtn
          ? _c(
              "el-button",
              {
                staticClass: "mail-btn",
                staticStyle: {
                  "background-color": "#26c393 !important",
                  "border-color": "#26c393 !important",
                },
                attrs: {
                  size: "small",
                  loading: _vm.btnLoading,
                  type: "primary",
                },
                on: {
                  click: function ($event) {
                    return _vm.sendApproveMail(2)
                  },
                },
              },
              [_vm._v(" 审批发送")]
            )
          : _vm._e(),
        _c(
          "el-button",
          {
            staticClass: "mail-btn",
            attrs: { size: "small", loading: _vm.btnLoading, type: "primary" },
            on: {
              click: function ($event) {
                return _vm.sendMail(2)
              },
            },
          },
          [_vm._v("发送")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }