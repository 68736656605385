var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "img-card" },
    [
      _vm.fileData.status === "success"
        ? _c("el-image", {
            staticClass: "img",
            staticStyle: { width: "100px", height: "100px" },
            attrs: {
              src: _vm.fileData.url || _vm.fileData.response.data.fileUrl,
              "preview-src-list": [
                _vm.fileData.url || _vm.fileData.response.data.fileUrl,
              ],
              fit: "cover",
            },
          })
        : _c("i", { staticClass: "el-icon-picture img" }),
      _c("div", [
        _c("p", { staticClass: "name" }, [_vm._v(_vm._s(_vm.fileData.name))]),
        _c("p", { staticClass: "size" }, [_vm._v(_vm._s(_vm.size))]),
      ]),
      _c("i", { staticClass: "el-icon-close", on: { click: _vm.deleteFile } }),
      _vm.fileData.percentage !== 100
        ? _c("el-progress", {
            staticClass: "progress",
            attrs: {
              "text-inside": true,
              "stroke-width": 26,
              percentage: Math.floor(_vm.fileData.percentage),
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }