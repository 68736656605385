<!--
 * @Author: pengyu
 * @Date: 2021-02-24 14:09:24
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-03-25 16:56:48
 * @Description: file content
-->
<template>
  <div class="collapse-area">
    <div :class="[node.isFirst ? 'first-node' : '', 'parent-node', node.cur ? 'cur' : '']" @click="handleClassClick">
      <en-icon size="mini" v-show="node.hasChildren" :name="!node.open ? 'jia' : 'jian'" @click.native.stop="node.open = !node.open"></en-icon>
      <en-icon size="mini" v-show="!node.hasChildren"></en-icon>
      <span>{{ node.name }}</span>
    </div>
    <div class="node-list" v-show="node.open">
      <tree-node v-for="(itm, idx) in node.nodes" :key="idx" :node="itm"></tree-node>
    </div>
  </div>
</template>

<script>
import treeNode from "./tree-node";

export default {
  name: "CollapseTree",
  components: { treeNode },
  inject: ["$home"],
  props: {
    node: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  methods: {
    /**
     * @description 点击分类
     */
    handleClassClick() {
      this.$home.handleClassClick(this.node);
    }
  }
};
</script>

<style lang="scss" scoped>
.node {
  font-size: 12px;
  text-align: left;
  padding: 0 10px;
  color: #636c78;
  .en-icon {
    margin-right: 10px;
  }
  &:hover {
    background: #f5f8fd;
  }
}

.collapse-area {
  width: 100%;
  // border: 1px solid #e8ecf2;
  border-radius: 3px;
  // margin-bottom: 10px;
  .parent-node {
    // height: 42px;
    // line-height: 42px;
    padding: 10px 10px;

    border-radius: 3px;
    @extend .node;
    &.cur {
      // background: #3e90fe;
      color: #3e90fe;
    }
  }
  .first-node {
    // background: #3e90fe;
    // color: #fff;
    &:hover {
      // background: #3e90fe;
    }
    &.cur {
      // background: #3e90fe;
      // color: #fff;
    }
  }
}
</style>
