<!--
 * @Author: zoujp
 * @Date: 2021-03-03 11:48:41
 * @LastEditTime: 2021-03-05 17:19:21
 * @LastEditors: Please set LastEditors
 * @Description: 通讯录-新增分类
 * @FilePath: \mailbox\src\views\AddressBook\addClassify.vue
-->
<template>
  <el-dialog :title="edData && edData.id ? '编辑分组' : '新增分组'" width="700px" v-if="visiable" :visible.sync="visiable" :close-on-click-modal="false" @close="closeDialog">
    <div class="content-wrap" v-loading="loading">
      <el-form ref="form" :model="form" label-width="60px">
        <el-form-item label="分组名称">
          <el-input v-model="form.name" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="分组图标">
          <div class="icon-group">
            <div class="icon-mod" v-for="(item, idx) in iconData" :key="idx" :class="[item.cur ? 'cur' : '']" @click="clickIcon(item.id)">
              <en-icon :name="item.id"></en-icon>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="图标颜色">
          <el-color-picker v-model="form.color"></el-color-picker>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <div class="btn-cls" @click="save">
        <div class="btn-txt">确定</div>
      </div>
    </span>
  </el-dialog>
</template>
<script>
import { addressBookService } from "@/api/mailBox/addressBook";
/* eslint-disable */
export default {
  name: "addClassify",
  props: {
    message: {
      type: String,
      default: () => ""
    },
    editData: {
      type: Object,
      default: () => {}
    }
  },
  inject: ["$home"],
  data() {
    return {
      visiable: false,
      form: {
        id: "",
        name: "",
        color: "#26c494",
        icon: ""
      },
      edData: this.editData,
      selIcon: "wendangleixing-wendangguanli",
      loading: false,
      iconData: [
        {
          id: "wendangleixing-wendangguanli",
          cur: true
        },
        {
          id: "mobanshixin-yewujianmo4",
          cur: false
        },
        {
          id: "mobanshixin-yewujianmo6",
          cur: false
        },
        {
          id: "mobanshixin-yewujianmo20",
          cur: false
        },
        {
          id: "mobanshixin-yewujianmo22",
          cur: false
        },
        {
          id: "mobanshixin-yewujianmo28",
          cur: false
        }
      ]
    };
  },
  watch: {
    editData(val) {
      this.edData = val;
      this.initEditData();
    }
  },
  mounted() {
    this.initEditData();
  },
  computed: {},
  methods: {
    initEditData() {
      if (this.edData?.id) {
        const ed = this.edData;
        this.form.id = ed.id;
        this.form.name = ed.name;
        this.form.color = ed.color;
        this.form.icon = ed.icon;
        this.clickIcon(ed.icon);
      } else {
        this.form.id = "";
        this.form.name = "";
        this.form.color = "#26c494";
        this.form.icon = "";
      }
    },
    closeDialog() {
      this.visiable = false;
    },
    clickIcon(id) {
      this.iconData.forEach((item) => {
        if (item.id === id) {
          item.cur = true;
          this.selIcon = id;
        } else {
          item.cur = false;
        }
      });
    },
    async save() {
      if (!this.form.name) {
        this.$message({
          message: "分组名称不能为空",
          type: "warning"
        });
        return true;
      }
      try {
        this.loading = true;
        const params = {
          userId: this.$store.state.userInfo.userId
        };
        this.form.icon = this.selIcon;
        Object.assign(params, this.form);
        if (this.editData?.id) {
          params.id = this.editData.id;
          await addressBookService.modifyContactClassify(params);
        } else {
          await addressBookService.addContactClassify(params);
        }
        this.loading = false;
        this.visiable = false;
        this.$message({
          message: "保存成功",
          type: "success"
        });
        this.$home.saveClassifyCallback();
      } catch (error) {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .el-dialog {
  border-radius: 4px;
}
/deep/ .el-form-item {
  margin-bottom: 15px;
  .el-form-item__label {
    font-size: 12px;
    color: #333;
  }
  .el-input__inner {
    font-size: 12px;
    color: #333;
  }
}
.content-wrap {
  .icon-group {
    display: flex;
    padding: 10px;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    .icon-mod {
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
      cursor: pointer;
      .en-icon {
        width: 20px;
        height: 20px;
        color: #a8b5c6;
        margin: 8px;
      }
      &.cur {
        background: #3e90fe;
        border-radius: 4px;
        .en-icon {
          color: #fff;
        }
      }
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .btn-cls {
    width: 70px;
    height: 30px;
    background: #3e90fe;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
  }
}
</style>
