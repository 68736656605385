var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mail-attach" },
    [
      _vm.hasFolderMenuAuth
        ? _c(
            "el-popover",
            {
              attrs: {
                placement: "bottom",
                "popper-class": "attach-popper",
                width: "140",
              },
            },
            [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "operation-item" },
                  [
                    _c(
                      "el-upload",
                      {
                        staticClass: "upload-attach",
                        attrs: {
                          multiple: "",
                          name: "storage_file",
                          accept:
                            "image/png,image/gif,image/bmp,image/jpeg,audio/mp4,video/mp4,\n            aplication/zip,aplication/rar,aplication/7z,\n            application/rtf, text/rtf,text/plain,\n            application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-powerpoint,\n            application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/pdf",
                          action: _vm.action,
                          data: _vm.uploadData,
                          limit: 10,
                          "on-exceed": _vm.handleExceed,
                          "on-success": _vm.uploadSuccess,
                          "on-progress": _vm.uploadProgress,
                          "show-file-list": false,
                          "file-list": _vm.fileList,
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              "font-size": "12px",
                              "line-height": "1",
                              padding: "0",
                              color: "#606266",
                            },
                            attrs: { type: "text" },
                          },
                          [
                            _c(
                              "i",
                              {
                                staticClass: "iconfont",
                                staticStyle: {
                                  "font-size": "16px",
                                  position: "relative",
                                  top: "1px",
                                  color: "#3a88db",
                                },
                              },
                              [_vm._v("")]
                            ),
                            _vm._v(" 本地文件 "),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "operation-item",
                    on: { click: _vm.openDialog },
                  },
                  [
                    _c("div", [
                      _c(
                        "i",
                        {
                          staticClass: "iconfont",
                          staticStyle: {
                            "font-size": "16px",
                            position: "relative",
                            top: "1px",
                            color: "#f9bb32",
                            "margin-left": "6px",
                          },
                        },
                        [_vm._v("")]
                      ),
                      _vm._v(" 我的文件夹 "),
                    ]),
                  ]
                ),
              ]),
              _c(
                "el-button",
                {
                  staticStyle: {
                    "font-size": "12px",
                    color: "#3e90fe",
                    height: "20px",
                    "margin-bottom": "18px",
                  },
                  attrs: { slot: "reference", type: "text" },
                  slot: "reference",
                },
                [
                  _vm._v(" 添加附件 "),
                  _c("i", {
                    staticClass: "el-icon-paperclip",
                    staticStyle: {
                      "font-size": "16px",
                      position: "relative",
                      top: "1px",
                    },
                  }),
                ]
              ),
            ],
            1
          )
        : _c(
            "el-upload",
            {
              staticClass: "upload-attach",
              attrs: {
                multiple: "",
                name: "storage_file",
                accept:
                  "image/png,image/gif,image/bmp,image/jpeg,audio/mp4,video/mp4,\n      aplication/zip,aplication/rar,aplication/7z,\n      application/rtf, text/rtf,text/plain,\n      application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-powerpoint,\n      application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/pdf",
                action: _vm.action,
                data: _vm.uploadData,
                limit: 10,
                "on-exceed": _vm.handleExceed,
                "on-success": _vm.uploadSuccess,
                "on-progress": _vm.uploadProgress,
                "show-file-list": false,
                "file-list": _vm.fileList,
              },
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: {
                    "font-size": "12px",
                    color: "#3e90fe",
                    height: "20px",
                    "margin-bottom": "18px",
                  },
                  attrs: { type: "text" },
                },
                [
                  _vm._v(" 添加附件 "),
                  _c("i", {
                    staticClass: "el-icon-paperclip",
                    staticStyle: {
                      "font-size": "16px",
                      position: "relative",
                      top: "1px",
                    },
                  }),
                ]
              ),
            ],
            1
          ),
      _c(
        "div",
        { staticClass: "file-list" },
        _vm._l(_vm.fileList, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "file-item" },
            [
              _c(_vm.getComponentName(item), {
                tag: "component",
                attrs: { "file-data": item },
                on: {
                  deleteFile: function ($event) {
                    return _vm.fileList.splice(index, 1)
                  },
                },
              }),
            ],
            1
          )
        }),
        0
      ),
      _c("folder-dialog", {
        ref: "folderDialog",
        on: { confirmSelect: _vm.confirmSelectFile },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }